// @flow

import * as React from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { TextInputField } from '../Form/InputField'
import { ErrorMessage } from '../Form/index'
import { ButtonBlue } from '../Button'
import { RobotoMedium } from '../../Typography/Fonts'
import { Colors } from '../../Colors'
import { LeftLoginAction } from '../Helper'
import ArrowLeftSvg from '../../icons/ArrowLeftSvg'
import CheckSvg from '../../icons/CheckSvg'
import LinkToSvg from '../../icons/LinkToSvg'

const Wrapper = styled.div`
  width: 100%;
`

const Link = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 12px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 14px;
  color: ${Colors.white30};
  transition: color 0.15s ease-in-out;

  &:hover {
    color: ${Colors.white100};
  }
`

const ForgotPassword = styled(Link)`
  svg {
    width: 16px;
    height: 16px;
    margin-left: 4px;
  }

  path {
    transition: fill-opacity 0.15s ease-in-out;
  }

  &:hover {
    path {
      fill-opacity: 1;
    }
  }
`

const CheckBoxContainer = styled(Link)`
  color: ${({ isActive }) => (isActive ? Colors.white100 : Colors.white30)};

  svg {
    margin-right: 4px;
    width: 16px;
    height: 16px;
  }
`

const LinksContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`

const ButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 30px;
`

const CheckBox = styled.div`
  width: 16px;
  height: 16px;
  border: solid 1px ${Colors.white30};
  border-radius: 8px;
  margin-right: 4px;
`

const Description = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 16px;
  color: ${Colors.white50};
  margin-top: 20px;
`

const RequestedWithSuccessDescription = styled(Description)`
  text-align: center;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`

type Props = {|
  onLogin: (
    email: string,
    password: string,
    rememberMe: boolean,
  ) => Promise<{
    success: boolean,
    errors: { field: ?string, message: string }[],
  }>,
  onRequestNewPassword: (email: string) => Promise<{
    success: boolean,
    errors: { field: ?string, message: string }[],
  }>,
  t: string => string,
  tReady: boolean,
|}

type State = {|
  email: string,
  password: string,
  loginErrors: { field: ?string, message: string }[],
  rememberMe: boolean,
  loginInProgress: boolean,
  requestNewPasswordMode: boolean,
  requestNewPasswordInProgress: boolean,
  requestNewPasswordErrors: { field: ?string, message: string }[],
  newPasswordRequestedWithSuccess: boolean,
|}

class LoginForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      email: 'seller@custhome.app',
      password: '123456',
      loginErrors: [],
      rememberMe: true,
      loginInProgress: false,
      requestNewPasswordMode: false,
      requestNewPasswordInProgress: false,
      requestNewPasswordErrors: [],
      newPasswordRequestedWithSuccess: false,
    }
  }

  handleLoginFormSubmit = async () => {
    const { onLogin } = this.props
    const { email, password, rememberMe } = this.state

    this.setState({ loginInProgress: true, loginErrors: [] }, async () => {
      const { success, errors } = await onLogin(email, password, rememberMe)

      if (!success) {
        this.setState({ loginErrors: errors, loginInProgress: false })
      }
    })
  }

  handleNewPasswordFormSubmit = async () => {
    const { onRequestNewPassword } = this.props
    const { email } = this.state

    this.setState(
      { requestNewPasswordInProgress: true, requestNewPasswordErrors: [] },
      async () => {
        const { success, errors } = await onRequestNewPassword(email)

        if (!success) {
          this.setState({
            requestNewPasswordErrors: errors,
            requestNewPasswordInProgress: false,
          })
        } else {
          this.setState({
            newPasswordRequestedWithSuccess: true,
          })
        }
      },
    )
  }

  render() {
    const {
      email,
      password,
      loginErrors,
      rememberMe,
      loginInProgress,
      requestNewPasswordMode,
      requestNewPasswordInProgress,
      requestNewPasswordErrors,
      newPasswordRequestedWithSuccess,
    } = this.state
    const { t, tReady } = this.props

    if (!tReady) {
      return false
    }
    return (
      <Wrapper>
        {requestNewPasswordMode ? (
          newPasswordRequestedWithSuccess ? (
            <React.Fragment>
              <IconContainer>
                <CheckSvg />
              </IconContainer>
              <RequestedWithSuccessDescription>
                {t('login.resetRequested')} {email}.
              </RequestedWithSuccessDescription>
              <LeftLoginAction
                onClick={() =>
                  this.setState({
                    requestNewPasswordMode: false,
                    newPasswordRequestedWithSuccess: false,
                  })
                }
              >
                <ArrowLeftSvg />
                {t('login.login')}
              </LeftLoginAction>
            </React.Fragment>
          ) : (
            <form
              onSubmit={e => {
                e.preventDefault()
                this.handleNewPasswordFormSubmit()
              }}
            >
              <TextInputField
                label={t('login.email')}
                value={email}
                onChange={e => {
                  this.setState({ email: e.target.value })
                }}
                autoComplete="email"
              />

              {requestNewPasswordErrors.map(({ message }) => (
                <ErrorMessage key={message}>{message}</ErrorMessage>
              ))}

              <Description>{t('login.resetPasswordDescription')}</Description>

              <ButtonContainer>
                <ButtonBlue
                  disabled={requestNewPasswordInProgress}
                  type="submit"
                >
                  {t('login.send')}
                </ButtonBlue>
              </ButtonContainer>

              <LeftLoginAction
                onClick={() =>
                  this.setState({
                    requestNewPasswordMode: false,
                    requestNewPasswordErrors: [],
                  })
                }
              >
                <ArrowLeftSvg />
                {t('login.login')}
              </LeftLoginAction>
            </form>
          )
        ) : (
          <form
            onSubmit={e => {
              e.preventDefault()
              this.handleLoginFormSubmit()
            }}
          >
            <TextInputField
              label={t('login.userLogin')}
              type="text"
              value={email}
              onChange={e => {
                this.setState({ email: e.target.value })
              }}
              autoComplete="email"
            />

            <TextInputField
              label={t('login.password')}
              type="password"
              value={password}
              onChange={e => {
                this.setState({ password: e.target.value })
              }}
              autoComplete="current-password"
            />

            {loginErrors.map(({ message }) => (
              <ErrorMessage key={message}>{message}</ErrorMessage>
            ))}

            <LinksContainer>
              <CheckBoxContainer
                isActive={rememberMe}
                onClick={() => this.setState({ rememberMe: !rememberMe })}
              >
                {rememberMe ? <CheckSvg /> : <CheckBox />}
                {t('login.remember')}
              </CheckBoxContainer>

              <ForgotPassword
                onClick={() =>
                  this.setState({
                    requestNewPasswordMode: true,
                    loginErrors: [],
                  })
                }
              >
                {t('login.forgot')} <LinkToSvg />
              </ForgotPassword>
            </LinksContainer>

            <ButtonContainer>
              <ButtonBlue disabled={loginInProgress} type="submit">
                {t('login.login')}
              </ButtonBlue>
            </ButtonContainer>
          </form>
        )}
      </Wrapper>
    )
  }
}

export default withTranslation()(LoginForm)
